import _ from 'lodash'
import { useEffect, useState } from 'react'
import { usePacket, useProject, useTrackerDropdowns, useUpdatePacket } from 'tracker/api'
import dayjs from '@/lib/dayjs'

export const usePacketFurnishings = (projectId, packetId, isModalOpen) => {
    // Project API
    const { data: project, ...projectApi } = useProject(
        { projectId },
        { enabled: projectId && isModalOpen }
    )
    // Packet API
    const { data: packet, ...packetApi } = usePacket(packetId, {
        enabled: !!packetId && isModalOpen,
    })
    // Update Packet API
    const { mutate: updatePacket, ...updatePacketApi } = useUpdatePacket(projectId)

    const [furnishings, setFurnishings] = useState([])
    const selectedFurnishings = furnishings?.filter(({ selected }) => selected)

    useEffect(() => {
        if (!packetId && project?.furnishings) {
            setFurnishings(
                Object.values(project?.furnishings)
                    ?.filter((furnishing) => !furnishing?.is_deleted)
                    ?.map((furnishing) => ({
                        ...furnishing,
                        selected: false,
                    }))
            )
        }
        if (packet?.furnishings && project?.furnishings) {
            let packetFurnishings = packet?.furnishings
            let projectFurnishings = project?.furnishings

            for (const key in projectFurnishings) {
                projectFurnishings[key].selected = !!packetFurnishings?.[key]
            }

            let mergedObjects = _.merge({}, packetFurnishings, project?.furnishings)
            setFurnishings(
                Object.values(mergedObjects)?.filter((furnishing) => !furnishing?.is_deleted)
            )
        }
    }, [packetId, packet?.furnishings, project?.furnishings])

    function selectFurnishing(furnishingId, value = true) {
        setFurnishings(
            furnishings?.map((furnishing) =>
                furnishing._id === furnishingId ? { ...furnishing, selected: value } : furnishing
            )
        )
        if (packetId) {
            const furnishing = furnishings.find(({ _id }) => _id === furnishingId)
            updatePacket({
                packetId,
                data: {
                    furnishings: {
                        [furnishingId]: {
                            furnishing_id: furnishing?._id,
                            id: furnishing?.id,
                            _delete: !value,
                        },
                    },
                },
            })
        }
    }

    function getFurnishingsPostObject() {
        return furnishings
            ?.filter(({ selected }) => selected)
            ?.reduce(
                (returnObj, furnishing) => ({
                    ...returnObj,
                    [furnishing?._id]: {
                        furnishing_id: furnishing?._id,
                    },
                }),
                {}
            )
    }

    // Months
    const monthsWithFurnishings = _.groupBy(furnishings, ({ date }) =>
        dayjs(date).format('MMMM YYYY')
    )
    const furnishingMonths = Object.keys(monthsWithFurnishings)?.map((monthAndYear) => {
        let totalFurnishings = 0
        let totalBalance = 0
        let monthNumber = 0
        let allSelected = true // Added flag for all selected furnishings

        monthsWithFurnishings[monthAndYear].forEach(({ balance, selected, date }) => {
            totalFurnishings += 1
            totalBalance += Number(balance)
            if (!selected) {
                allSelected = false
            }
            monthNumber = dayjs(date).format('M')
        })

        const [month, year] = monthAndYear.split(' ')
        return {
            sort: year + monthNumber,
            month,
            year,
            furnishings: totalFurnishings,
            totalBalance,
            selected: allSelected, // Added selected flag
        }
    })

    function selectMonth(year, month, value) {
        setFurnishings(
            furnishings?.map((furnishing) => {
                const furnishingMonth = dayjs(furnishing.date).format('MMMM YYYY')
                if (furnishingMonth === `${month} ${year}`) {
                    return { ...furnishing, selected: value }
                }
                return furnishing
            })
        )
        if (packetId) {
            const updatedFurnishings = furnishings.filter(
                ({ date }) => dayjs(date).format('MMMM YYYY') === `${month} ${year}`
            )
            const postObject = updatedFurnishings.reduce(
                (returnObj, furnishing) => ({
                    ...returnObj,
                    [furnishing?._id]: {
                        furnishing_id: furnishing?._id,
                        _delete: !value,
                    },
                }),
                {}
            )
            updatePacket({
                packetId,
                data: {
                    furnishings: postObject,
                },
            })
        }
    }

    return {
        furnishings: furnishings.sort((a, b) => dayjs(b.date).diff(dayjs(a.date))),
        selectFurnishing,
        selectedFurnishings,
        furnishingMonths,
        selectMonth,
        getFurnishingsPostObject,
        isLoading: updatePacketApi.isLoading,
    }
}
